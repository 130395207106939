header{
  padding: 1rem;
  background: $white;


  h1{
    margin: 0;
  }

  p{
    margin: 0;
  }
}